import homeTopSlides from '~/js/sections/root/includes/home-top-slides';
import '~/js/sections/root/includes/slider-countdown';

const topSlides = [];
const topSliderWrap = document.querySelector('#hero-slider .slide-wrap');
let counter = 0;
const caption = document.getElementById('caption');
const playPause = document.getElementById('playPause');
const $sliderTop = $('#hero-slider .slide-wrap');

const slickSlider = () => {

  const $statusSliderTop = $('#hero-slider .js-slick-slider-top-counter');

  $sliderTop.on('init reInit afterChange', (event, slick, currentSlide) => {
    const i = (currentSlide ? currentSlide : 0) + 1;

    $statusSliderTop.text(`${i}/${slick.slideCount}`);
  });

  $sliderTop.slick({
    centerMode: true,
    centerPadding: '0',
    cssEase: 'linear',
    fade: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 10000,
    prevArrow: $('#hero-slider .js-prev-slide-in-slider-top'),
    nextArrow: $('#hero-slider .js-next-slide-in-slider-top'),
    responsive: [{
      breakpoint: 767,
      settings: {
        autoplay: false,
      }
    }]
  });

  $sliderTop.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    const slidesLength = slick.$slides.length;

    if (nextSlide <= slidesLength - 2) {
      const nextSlide1 = $sliderTop.find(`div[data-slick-index='${nextSlide}']`).find('.slider-item-inner')[0];
      const nextNextSlide = $sliderTop.find(`div[data-slick-index='${nextSlide + 1}']`).find('.slider-item-inner')[0];

      const nextSlide1Image = $(nextSlide1).data('bg');
      const nextNextSlideImage = $(nextNextSlide).data('bg');

      nextSlide1.style.backgroundImage = `url(${nextSlide1Image})`;
      nextNextSlide.style.backgroundImage = `url(${nextNextSlideImage})`;
    }
    caption.innerHTML = homeTopSlides.slides[nextSlide].caption;
  });
};

$(playPause).click((event) => {
  if (event.target.classList.contains('pause')) {
    $sliderTop.slick('slickPause');
    $(playPause).removeClass('pause').addClass('play');
  } else {
    $sliderTop.slick('slickPlay');
    $(playPause).removeClass('play').addClass('pause');
  }
});


homeTopSlides.slides.forEach((slide, index, array) => {
  if (index !== 0) {
    if (!slide.customizeLayout) {
      topSlides.push(`
                <div class='slider-item btc-countdown-slide'>
                    <a href="${slide.link}" data-ibm="${slide.ibmClickTag}">
                        <div class='slider-item-inner desktopItems' data-bg='${slide.img}'></div>
                        <div class='slider-item-inner mobileItems' data-bg='${slide.mobimg}' style="background-image: url('${slide.mobimg}')"></div>
                    </a>
                </div>
            `);
    }
    else {
      topSlides.push(`
          <div class='slider-item btc-countdown-slide'>
              <a href="${slide.link}" data-ibm="${slide.ibmClickTag}">
                  <div class='slider-item-inner desktopItems' data-bg='${slide.img}'>  ${slide.additionalElements} </div>
              </a>
          </div>
      `);
    }

  }
  counter++;
  if (counter === array.length) {
    $(topSliderWrap).append(topSlides.join(''));
    slickSlider();
  }
});
