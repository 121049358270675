console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
let actualHour = new Date();
console.log("time",actualHour.getTime());

const homeTopSlides = {
  slides: [
    {
      caption: "Family Fun: Get Closer to the Caribbean",
      link: "/deals/uk-offers/",
      img: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/spring-sale/Beaches_FamilyFun_HpBanner_Desktop.jpg",
      mobimg: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/spring-sale/Beaches_FamilyFun_HpBanner_Mobile.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },
    {
      caption: "Treasure Beach Village",
      link: "/resorts/turks-caicos/treasure-beach/",
      img: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/banner-desktop.jpg",
      mobimg: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/banner-mobile.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },
    // {
    //   caption: "Book by 31st March 2025",
    //   link: "/deals/uk-offers/",
    //   img: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/Beaches_FamilyFun_HpBanner_Desktop.jpg",
    //   mobimg: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/Beaches_FamilyFun_HpBanner_Mobile.jpg",
    //   ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
    //   customizeLayout: false,
    // },
    {
      caption: "All-New Beachfront Villas & Penthouse Collection",
      link: "/resorts/negril/",
      img: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/Beaches_New-Accommodations-HP.jpg",
      mobimg: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/Beaches_New-Accommodations-HP.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    },
    {
      caption: "Unique Caribbean Holidays Ltd.",
      link: "/uchl/",
      img: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/uchl-desktop.jpg",
      mobimg: "https://cdn.sandals.com/beaches/v12/images/home/top-slides/hero-mobile.jpg",
      ibmClickTag: "HP Top Carousel-_-Define_Homepage-_-N/A",
      customizeLayout: false,
    }
  ],
};

module.exports = homeTopSlides;
